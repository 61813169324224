import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { Arrow } from "../templates/index-page";


const SuccessPage = () => (
  <Layout>
    <div className="container simple-page mt-6 mb-6">
      <h1>Danku!</h1>
      <p>Uw formulierinzending is succesvol ontvangen.</p>
      <Link to="/" className="nav-link meer-info" title="home">Terug naar de home pagina <Arrow color={'white'} /></Link>
    </div>
  </Layout>
);

export default SuccessPage;
